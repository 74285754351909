var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap__content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3"},[_c('v-sidebar',{attrs:{"active":['ReportingAnalyticManagement']}})],1),_c('div',{staticClass:"col-xl-10 col-lg-9"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"card__content"},[_c('HeaderBack',{attrs:{"title":'СВЕДЕНИЯ О ВЫПОЛНЕНИИ ЗАПЛАНИРОВАННЫХ НА ТЕКУЩИЙ ГОД ОБЪЕМОВ ФИНАНСОВЫХ СРЕДСТВ ПОЛЬЗОВАТЕЛЯМИ ДЛЯ РАЗВИТИЯ РЫБНОГО ХОЗЯЙСТВА'}}),_c('div',{staticClass:"card__content--body"},[_c('div',{staticClass:"table__block--content"},[_c('div',{staticClass:"table__block--filter"},[_c('div',{staticClass:"table__filter--left"},[_c('div',{staticClass:"search-main"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('MultiSelect',{staticClass:"multiselect__check",attrs:{"track-by":"value","label":"name","placeholder":"Выбрать раздел","preserve-search":false,"required":true,"multiple":false,"close-on-select":true,"options":_vm.quarters},model:{value:(_vm.quarter),callback:function ($$v) {_vm.quarter=$$v},expression:"quarter"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('components.not_found')))]),_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('components.not_found')))]),_c('template',{slot:"placeholder"},[_vm._v(_vm._s(_vm.$t('placeholder.choose')))])],2)],1)])])])]),_c('div',{staticClass:"v-data-table table__analytic v-data-table--has-bottom theme--light"},[_c('div',{staticClass:"v-data-table__wrapper"},[_c('table',{style:({
													borderLeft: '1px solid #D3E7FB',
													borderRight: '1px solid #D3E7FB',
												})},[_c('thead',{staticClass:"v-data-table-header"},[_c('tr',{style:({
															borderTop: '1px solid #D3E7FB',
															borderBottom: '1px solid #D3E7FB',
														})},[_c('th',[_vm._v(" "+_vm._s(_vm.headers[0].text)+" ")]),_vm._l((_vm.fields[_vm.selectedQuarter]),function(field,fieldInd){return [(field)?_c('th',{key:fieldInd,attrs:{"colspan":"2"}},[_c('div',{style:({
																		width: '220px',
																		whiteSpace: 'break-spaces',
																	})},[_vm._v(" "+_vm._s(field)+" ")])]):_vm._e()]})],2),_c('tr',{style:({
															borderTop: '1px solid #D3E7FB',
															borderBottom: '1px solid #D3E7FB',
														})},[_c('th'),_vm._l((_vm.fields[_vm.selectedQuarter]),function(field,fieldInd){return [(field)?[_c('th',{key:fieldInd + '1'},[_vm._v("Запланировано")]),_c('th',{key:fieldInd + '2'},[_vm._v("Фактически")])]:_vm._e()]})],2)]),_c('tbody',_vm._l((_vm.analytic),function(region,regionInd){return _c('tr',{key:regionInd},[_c('td',[_vm._v(_vm._s(region.region_name))]),_vm._l((Object.keys(_vm.fields[_vm.selectedQuarter])),function(field,fieldInd){return _c('td',{key:fieldInd,staticClass:"text-start"},[_c('div',{style:({
																	width: '110px',
																	whiteSpace: 'break-spaces',
																})},[_vm._v(" "+_vm._s(region[_vm.selectedQuarter][field])+" ")])])})],2)}),0)])])])])])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }